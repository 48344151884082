<template>
  <div class="banner-wrapper" :style="{ backgroundImage: `url(${bgUrl})` }">
    <div class="banner-desc">
      <img style="width: 552px; height: 133px; margin-top: 184px" :src="descUrl" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 背景图名称
    bgName: {
      required: true,
      type: String
    },
    // 描述是否为透明
    descType: {
      required: true,
      type: String
    }
  },
  computed: {
    // 背景图地址
    bgUrl() {
      const url = require(`@/assets/images/${this.bgName}`);
      return url;
    },
    // 描述
    descUrl() {
      const url = require(`@/assets/images/${this.descType}`);
      return url;
    }
  }
};
</script>

<style lang="less" scoped>
.banner-wrapper {
  position: relative;
  height: 500px;
  overflow: hidden;
  background-size: 100% 100%;
  .banner-desc {
    width: 1200px;
    height: 133px;
    margin: 0 auto;
  }
}
</style>
